import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import Button from 'material-ui/Button';
//import FillDialog from './FillDialog.js';

const styles = theme => ({
    container: {
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    bigTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});
class CoreDataEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fillDialogOpen: false
        }
    }

    handleSetField = (fieldName, fieldType) => event => {
        const dr = this.props.dataRequest;
        let value;
        if (fieldType === "string") {
            value = event.target.value;
        } else if (fieldType === "float") {
            value = parseFloat(event.target.value);
        } else if (fieldType === "integer") {
            value = parseInt(event.target.value, 10);
        }
        dr[fieldName] = value;
        this.props.requestUpdated && this.props.requestUpdated(dr);
    };

    handleUpdateSale = (index) => event => {
        const dr = this.props.dataRequest;
        dr.salesForecast.params[index] = parseInt(event.target.value, 10);
        this.props.requestUpdated && this.props.requestUpdated(dr);

    };

    openDialog = () => {
        console.log(this);
        this.setState({fillDialogOpen: true});
    };

    closeDialog = (data) => {
        if (data.start !== undefined) {
            const dr = this.props.dataRequest;
            for (let x = data.start ; x < data.end ; x++) {
                dr.salesForecast.params[x] = data.values[x];
            }
            this.props.requestUpdated && this.props.requestUpdated(dr);
        }
        this.setState({fillDialogOpen: false});
    };

    render() {
        const classes = this.props.classes;
        const dataRequest = this.props.dataRequest;
        if (!dataRequest.salesForecast) {
            dataRequest.salesForecast = {
                "name": "FixedCumulative",
                "params": []
            }
        }
        const salesForecast = dataRequest.salesForecast.params;
        while (salesForecast.length < dataRequest.simulationDayCount) {
            salesForecast.push(salesForecast[salesForecast.length - 1]);
        }
        while (salesForecast.length > dataRequest.simulationDayCount) {
            salesForecast.pop();
        }
        return (
            <div className={classes.container}>
                <Paper>
                    <Grid container>
                        <Grid item xs={12}>
                            <div>
                                <h3>Inventory Data</h3>
                            </div>
                        </Grid>
                        {/*
                        <Grid item xs={3}>
                            <Button raised="true" color="primary" onClick={this.openDialog}>Fill</Button>
                            <FillDialog open={this.state.fillDialogOpen} values={salesForecast} max={salesForecast.length} onRequestClose={this.closeDialog} round={true}/>
                        </Grid>
                        */}
                        <Grid item xs={3}>
                            <TextField value={dataRequest.maxEntities} className={classes.bigTextField} onChange={this.handleSetField("maxEntities", "integer")} type="number" label="Product Inventory"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField value={dataRequest.simulationDayCount} className={classes.bigTextField} onChange={this.handleSetField("simulationDayCount", "integer")} type="number" label="Simulation Length"/>
                        </Grid>
                        {/*
                        <Grid item xs={12}>
                            <div><h3>Cumulative Sales Data</h3></div>
                        </Grid>
                        {salesForecast.map((entry, index) => {
                            return (<Grid item xs={1} key={index}>
                                <TextField value={entry} className={classes.textField} onChange={this.handleUpdateSale(index)} type="number" label={index + 1} error={(index > 0 && salesForecast[index - 1] > entry) || (index === 0 && entry < 0)}/>
                            </Grid>)
                        })}
                        */}
                    </Grid>
                </Paper>
            </div>
        );
    }
}
CoreDataEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreDataEdit);
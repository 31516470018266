import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BaseChart from './BaseChart.js';


const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class PriceChart extends React.Component {

    render() {
        if (this.props.response) {
            const req = this.props.dataRequest;
            const resp = this.props.response;
            const configNames = req.staticConfig.concat(req.pidConfig).concat(req.linearDemandConfig).concat(req.emsrConfig).map((config) => config.name);
            let visitorIndex = 0;
            const visitorIndexes = [];
            resp.dataRow.forEach((row) => {
                row.visitors.forEach((visitor) => visitorIndexes.push(visitorIndex++));
            });
            const data = configNames.map((name) => {
                const salePrices = [];
                resp.dataRow.forEach((row) => {
                    row.visitors.forEach((visitor) => {
                        salePrices.push(parseFloat(visitor.runEntries[name].salePrice));
                    });
                });
                return {
                    x: visitorIndexes,
                    y: salePrices,
                    name: name
                }
            });
            return <BaseChart data={data} title="Price per customer" xTitle="Customer Offers" yTitle="Sale Price"/>
        }
        else {
            return (<div>Awaiting data</div>);
        }
    }
}
PriceChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(PriceChart)
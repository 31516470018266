import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {IconButton, ListItem, ListItemText, Tooltip} from '@material-ui/core'
import { LinearProgress } from '@material-ui/core';
import PriceChart from './responsecomponents/PriceChart.js';
import PricePerDayChart from './responsecomponents/PricePerDayChart.js';
import SaleChart from './responsecomponents/SaleChart.js';
import SaleByDayChart from './responsecomponents/SaleByDayChart.js';
import RevenueChart from './responsecomponents/RevenueChart.js';
import RevenuePerDayChart from './responsecomponents/RevenuePerDayChart.js';
import AverageRevenuePerPriceChart from './responsecomponents/AverageRevenuePerPriceChart.js';
import SaleByVisitorChart from './responsecomponents/SaleByVisitorChart.js';
import VisitorTypeChart from './responsecomponents/VisitorTypeChart.js';
import DownloadLink from './responsecomponents/DownloadLink.js';
import PidErrorPerDayChart from './responsecomponents/PIDErrorPerDayChart.js';
import VisitorTypePerDayChart from './responsecomponents/VisitorTypePerDayChart.js';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Refresh from '@material-ui/icons/Refresh';
import Button from '@material-ui/core/Button';


const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class ResponsePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {customersOpen: false};
        if (props.buttonsCallback) {
            props.buttonsCallback([
                <Tooltip title="Refresh"><IconButton key="resend" onClick={this.resend}><Refresh /></IconButton></Tooltip>
                ]
            );
        }
    };

    componentWillReceiveProps = () => {
        this.resend();
    };
    resend = () => {
        this.setState({response: undefined});
    };

    send() {
        const rp = this;
        const xhr = new XMLHttpRequest();
        const message = JSON.stringify(this.props.dataRequest);
        xhr.open("POST", this.props.serviceURL);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                rp.setState({"response": JSON.parse(xhr.responseText)});
            }
        };
        xhr.send(message);
    }

    handleOpenCustomers = () => {
        this.setState({ customersOpen: !this.state.customersOpen});
    };

    render() {
        const classes = this.props;
        if (!this.state.response) {
            this.send();
            return (<div className={classes.root}><LinearProgress/></div>);
        } else {
            return (
                <div>
                    <VisitorTypePerDayChart response={this.state.response} requestUpdated={this.props.requestUpdated} dataRequest={this.props.dataRequest} />
                    <AverageRevenuePerPriceChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    <PricePerDayChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    <SaleByDayChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    <RevenuePerDayChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    <SaleByVisitorChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    <VisitorTypeChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    <DownloadLink dataRequest={this.props.dataRequest} response={this.state.response} />
                    <ListItem button onClick={this.handleOpenCustomers}>
                        <ListItemText inset primary="Additional Customer Charts"/>
                        {this.state.customersOpen ? <ExpandLess/> : <ExpandMore/> }
                    </ListItem>
                    <Collapse in={this.state.customersOpen} >
                        <PidErrorPerDayChart response={this.state.response} dataRequest={this.props.dataRequest} />
                    </Collapse>
                    <Collapse in={this.state.customersOpen} >
                        <PriceChart response={this.state.response} dataRequest={this.props.dataRequest}/>
                    </Collapse>
                    <Collapse in={this.state.customersOpen} >
                        <SaleChart response={this.state.response} dataRequest={this.props.dataRequest}/>
                    </Collapse>
                    <Collapse in={this.state.customersOpen} >
                        <RevenueChart response={this.state.response} dataRequest={this.props.dataRequest}/>
                    </Collapse>
                </div>);
        }
    }
}
ResponsePage.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ResponsePage);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from '@material-ui/core';

const styles = theme => ({
    container: {
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    bigTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});
class LinearPriceEdit extends React.Component {

    constructor(props) {
        super(props);
        const price = props.dataRequest.linearDemandConfig[props.priceIndex];
        if (price.useForecast === undefined) {
            price.useForecast = false;
        }
        this.state = {
            price: price
        }
    }

    componentWillReceiveProps = function(newprops) {
        this.setState({
            price: newprops.dataRequest.linearDemandConfig[newprops.priceIndex]
        });
    };

    updatePrice = function(price) {
        const dq = this.props.dataRequest;
        dq.linearDemandConfig[this.props.priceIndex] = price;
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({
            price: price
        });
    };

    handleSetPriceField = (fieldName, fieldType) => event => {
        const price = this.state.price;
        let value;
        if (fieldType === "string") {
            value = event.target.value;
        } else if (fieldType === "float") {
            value = parseFloat(event.target.value);
        } else if (fieldType === "integer") {
            value = parseInt(event.target.value, 10);
        }
        price[fieldName] = value;
        this.updatePrice(price);
    };

    useForecast = event => {
        const price = this.state.price;
        price.useForecast = event.target.checked;
        this.updatePrice(price);
    };

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.container}>
                <Paper>
                    <Grid container>
                        <Grid item xs={3}>
                            <TextField value={this.state.price.name} className={classes.bigTextField} onChange={this.handleSetPriceField("name", "string")} label="Name"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.startPrice1} className={classes.bigTextField} onChange={this.handleSetPriceField("startPrice1", "float")} label="Start Price 1"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.startPrice2} className={classes.bigTextField} onChange={this.handleSetPriceField("startPrice2", "float")} label="Start Price 2"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.minPrice} className={classes.bigTextField} onChange={this.handleSetPriceField("minPrice", "float")} label="Minimum Price"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.maxPrice} className={classes.bigTextField} onChange={this.handleSetPriceField("maxPrice", "float")} label="Maximum Price"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.checkInterval} className={classes.bigTextField} onChange={this.handleSetPriceField("checkInterval", "integer")} label="Check Interval"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.maxRateChange} className={classes.bigTextField} onChange={this.handleSetPriceField("maxRateChange", "float")} label="Max Rate Of Change"/>
                        </Grid>
                        {/*
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.inventoryMultiplier} className={classes.bigTextField} onChange={this.handleSetPriceField("inventoryMultiplier", "float")} label="Inventory control" />
                        </Grid>
                        */}
                        <Grid item xs={3}>
                            <FormControlLabel control={<Checkbox checked={this.state.price.useForecast} onChange={this.useForecast}/>} label="Use forecast model for demand planning" />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}
LinearPriceEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LinearPriceEdit);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BaseChart from './BaseChart.js';


const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class VisitorTypeChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: undefined,
            datatable: undefined
        };
    }

    render() {
        if (this.props.response) {
            const req = this.props.dataRequest;
            const resp = this.props.response;
            const visitorTypes = req.customers.map((entry) => entry.type);
            const counts = {};
            visitorTypes.forEach((visitor) => {counts[visitor] = 0});
            let totalVisitors = 0;
            resp.dataRow && resp.dataRow.forEach((row) => {
                row.visitors.forEach((visitor) => {
                    counts[visitor.customerEntry.customerType]+=visitor.customerEntry.groupSize;
                    totalVisitors += visitor.customerEntry.groupSize;
                });
            });
            const data = [{
                labels: visitorTypes,
                values: visitorTypes.map((visitor) => counts[visitor]),
                type: "pie"
            }];
            return (<BaseChart data={data} layout={{
                title: "Customer Types (Total " + totalVisitors + ")"
            }}/>)
        } else {
            return (<div>Awaiting data</div>);
        }
    }
}
VisitorTypeChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(VisitorTypeChart);
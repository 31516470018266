import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BaseChart from './BaseChart.js';

const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class SaleByDayChart extends React.Component {

    render() {
        if (this.props.response) {
            const req = this.props.dataRequest;
            const resp = this.props.response;
            const configNames = req.staticConfig.concat(req.pidConfig).concat(req.linearDemandConfig)
                .concat(req.emsrConfig).map((config) => config.name);
            const data = configNames.map((name) => {
                return {
                    x: resp.dataRow.map((_, index) => index),
                    y: resp.dataRow.map((row) => {
                        if (row.visitors.length > 0 && row.visitors[row.visitors.length - 1].runEntries[name]) {
                            return row.visitors[row.visitors.length - 1].runEntries[name].soldCount
                        } else {
                            return undefined
                        }
                    }),
                    mode: "lines",
                    name: name
                }
            });
            return (<BaseChart data={data} title="Accumulative Items Sold per Day" xTitle="Days on Sale" yTitle="Products Purchased"
                               response={this.props.response}/>);
        } else {
            return (<div>Awaiting data</div>);
        }
    }
}
SaleByDayChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(SaleByDayChart)
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {ListItem, ListItemText, ListItemSecondaryAction, TextField, Tooltip, IconButton} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import VisitorEdit from "./VisitorEdit.js";
import StaticPriceEdit from './StaticPriceEdit.js';
import PidPriceEdit from './PidPriceEdit.js';
import LinearPriceEdit from './LinearPriceEdit.js';
import CoreDataEdit from './CoreDataEdit.js';
import EMSREdit from './EMSREdit.js';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';
import ContentCopy from '@material-ui/icons/FileCopy';
import Undo from '@material-ui/icons/Undo';
import Launch from '@material-ui/icons/Launch';
import Snackbar from '@material-ui/core/Snackbar';
const style = theme => ({
    root: {
        width: "100%",
        background: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4
    },
    doubleNested: {
        paddingLeft: theme.spacing.unit * 8
    },
    button: {
        margin: theme.spacing.unit,
    }
});

class RequestPage extends React.Component {

    constructor(props) {
        super(props);
        const classes = this.props.classes;
        this.state = {
            visitorsOpen: false,
            pricePointOpen: false,
            staticPricePointsOpen: false,
            pidPricePointsOpen: false,
            linearPricePointsOpen: false,
            emsrPricePointsOpen: false,
            mode: "select",
            snackbarOpen: false,
            name: this.props.name,
            id: this.props.id,
        };
        console.log("id -> " + this.state.id);
        if (props.buttonsCallback) {
            props.buttonsCallback([
                <Tooltip title="Launch" key="launch"><IconButton variant="contained" color="default" classes={classes.button} onClick={this.onComplete}><Launch /></IconButton></Tooltip>,
                <Tooltip title="Revert to last saved" key="undo"><span><IconButton variant="contained" classes={classes.button} color="default" onClick={this.undo}><Undo /></IconButton></span></Tooltip>,
                <Tooltip title="Save" key="save"><span><IconButton variant="contained" color="default" classes={classes.button} onClick={this.save}><Save/></IconButton></span></Tooltip>
            ]);
        }
    };

    resetName = (name) => {
        this.props.collection.find({"name": name}, {"_id": 1}).first().then(x => {
            console.log(x, this.state.id);
            if (x !== undefined) {
                console.log(x._id, this.state.id, x._id.equals(this.state.id));
            }
            if (x === undefined || x._id.equals(this.state.id)) {
                this.setState({name: name, duplicateName: false});
            } else {
                this.setState({duplicateName: true});
            }
        })
    };
    handleOpenVisitors = () => {
        this.setState({ visitorsOpen: !this.state.visitorsOpen});
    };
    handleOpenPricePoints = () => {
        this.setState({pricePointOpen: !this.state.pricePointOpen});
    };
    handleOpenStaticPrice = () => {
        this.setState({staticPricePointsOpen: !this.state.staticPricePointsOpen});
    };
    handleOpenLinearPrice = () => {
        this.setState({linearPricePointsOpen: !this.state.linearPricePointsOpen});
    };

    handleOpenEMSRPrice = () => {
        this.setState({emsrPricePointsOpen: !this.state.emsrPricePointsOpen});
    };

    handleOpenPIDPrice = () => {
        this.setState({pidPricePointsOpen: !this.state.pidPricePointsOpen});
    };

    createNewPidSimulation = () => {
        const dq = this.props.dataRequest;
        dq.pidConfig.push({
            "kp": 0,
            "ki": 0,
            "kd": 0,
            "startPrice": 0,
            "minPrice": 0,
            "maxPrice": 1000,
            "name": "PID RQ " + (dq.pidConfig.length + 1),
            "resetForecast": false,
            "stickWithStartPrice": true
        });
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({mode: "pid", "index": dq.pidConfig.length - 1});
    };

    createNewLinearSimulation = () => {
        const dq = this.props.dataRequest;
        dq.linearDemandConfig.push({
            "startPrice1": 0,
            "startPrice2": 0,
            "checkInterval": 0,
            "minPrice": 0,
            "maxPrice": 0,
            "name": "Linear Demand RQ " + (dq.linearDemandConfig.length + 1),
            inventoryMultiplier: 1.0,
            useForecast: false
        });
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({mode: "linear", "index": dq.linearDemandConfig.length - 1});
    };

    createNewEmsrSimulation = () => {
        const dq = this.props.dataRequest;
        dq.emsrConfig.push({
            "mode": "EMSRa",
            "name": "EMSR RQ " + (dq.emsrConfig.length + 1),
            "protectionRows": []
        });
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({mode: "emsr", "index": dq.emsrConfig.length - 1});
    };

    createNewStaticSimulation = () => {
        const dq = this.props.dataRequest;
        dq.staticConfig.push({
            "salePrice": [0],
            "name": "Static RQ " + (dq.staticConfig.length + 1)
        });
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({mode: "static", "index": dq.staticConfig.length - 1});
    };

    createNewVisitor = () => {
        const dq = this.props.dataRequest;
        dq.customers.push({
            "type": "Visitor " + (dq.customers.length + 1)
        });
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({mode: "visitor", "index": dq.customers.length - 1});
    };

    setupCoreData = () => {
        this.setState({mode: "core"})
    };

    editVisitor = (value) => () => {
        this.setState({mode: "visitor", "index": value});
    };

    editStaticPrice = (value) => () => {
        this.setState({mode: "static", "index": value});
    };

    editPidPrice = (value) => () => {
        this.setState({mode: "pid", "index": value});
    };

    editLinearPrice = (value) => () => {
        this.setState({mode: "linear", "index": value});
    };

    editEmsrPrice = (value) => () => {
        this.setState({mode: "emsr", "index": value});
    };
    onComplete = () => {
        if (this.props.onComplete) {
            this.props.onComplete(this.props.dataRequest);
        }
    };

    undo = () => {

    };
    upload = () => {
        document.getElementById("fileUploader").click();
    };

    download = () => {
        const blob = new Blob([JSON.stringify(this.props.dataRequest)], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
            document.body.appendChild(link); //Firefox requires the link to be in the body
            link.download = this.state.filename + ".json";
            link.href = url;
            link.click();
            document.body.removeChild(link); //remove the link when done
        }
    };

    save = () => {
        const rq = {
            name: this.state.name,
            lastUpdated: new Date(),
            dataRequest: this.props.dataRequest,
            owner_id: this.props.user.id
        };
        if (this.state.id) {
            this.props.collection.updateOne({"_id": this.state.id}, {"$set": rq}).then(result => console.log("Result from update: ", result)).catch((e) => console.error(e));
        } else {
            this.props.collection.insertOne(rq).then(result => this.setState({"id": result.insertedId})).catch((e) => console.error(e));
        }
    };

    handleFileUpload = () => {
        const rqpage = this;
        const filelist = document.getElementById("fileUploader").files;
        if (filelist.length > 0) {
            console.log(filelist[0]);
            const reader = new FileReader();
            reader.onload = function(data) {
                rqpage.props.requestUpdated && rqpage.props.requestUpdated(JSON.parse(data.target.result));
                rqpage.setState({
                    "mode": "select",
                    "index": undefined,
                    "snackbarOpen": true,
                    "filename": filelist[0].name
                });
            };
            reader.readAsText(filelist[0]);
        }
    };

    removePid = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.pidConfig.length) {
            dq.pidConfig.splice(index, 1);
        }
        this.props.requestUpdated && this.props.requestUpdated(dq);
        const updatedState = {};
        if (this.state.mode === "pid" && this.state.index === index) {
            updatedState.mode = "select";
        }
        this.setState(updatedState);
    };

    removeLinear = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.linearDemandConfig.length) {
            dq.linearDemandConfig.splice(index, 1);
        }
        this.props.requestUpdated && this.props.requestUpdated(dq);
        const updatedState = {};
        if (this.state.mode === "linear" && this.state.index === index) {
            updatedState.mode = "select";
        }
        this.setState(updatedState);
    };

    removeEmsr = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.emsrConfig.length) {
            dq.emsrConfig.splice(index, 1);
        }
        this.props.requestUpdated && this.props.requestUpdated(dq);
        const updatedState = {};
        if (this.state.mode === "emsr" && this.state.index === index) {
            updatedState.mode = "select";
        }
        this.setState(updatedState);
    };
    duplicatePid = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.pidConfig.length) {
            const copyConfig = JSON.parse(JSON.stringify(dq.pidConfig[index]));
            copyConfig.name = "Copy of " + copyConfig.name;
            dq.pidConfig.push(copyConfig);
            this.props.requestUpdated && this.props.requestUpdated(dq);
            this.setState({"mode": "pid", "index": dq.pidConfig.length - 1});
        }
    };

    duplicateLinear = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.linearDemandConfig.length) {
            const copyConfig = JSON.parse(JSON.stringify(dq.linearDemandConfig[index]));
            copyConfig.name = "Copy of " + copyConfig.name;
            dq.linearDemandConfig.push(copyConfig);
            this.props.requestUpdated && this.props.requestUpdated(dq);
            this.setState({"mode": "linear", "index": dq.linearDemandConfig.length - 1});
        }
    };

    duplicateEmsr = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.emsrConfig.length) {
            const copyConfig = JSON.parse(JSON.stringify(dq.emsrConfig[index]));
            copyConfig.name = "Copy of " + copyConfig.name;
            dq.emsrConfig.push(copyConfig);
            this.props.requestUpdated && this.props.requestUpdated(dq);
            this.setState({"mode": "emsr", "index": dq.emsrConfig.length - 1});
        }
    };

    removeStatic = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.staticConfig.length) {
            dq.staticConfig.splice(index, 1);
        }
        this.props.requestUpdated && this.props.requestUpdated(dq);
        const updatedState = {};
        if (this.state.mode === "static" && this.state.index === index) {
            updatedState.mode = "select";
        }
        this.setState(updatedState);
    };

    duplicateStatic = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.staticConfig.length) {
            const staticCopy = JSON.parse(JSON.stringify(dq.staticConfig[index]));
            staticCopy.name = "Copy of " + staticCopy.name;
            dq.staticConfig.push(staticCopy);
            this.props.requestUpdated && this.props.requestUpdated(dq);
            this.setState({"mode": "static", "index": dq.staticConfig.length - 1});
        }
    };

    removeVisitor = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.customers.length) {
            dq.customers.splice(index, 1);
        }
        this.props.requestUpdated && this.props.requestUpdated(dq);
        const updatedState = {};
        if (this.state.mode === "visitor" && this.state.index === index) {
            updatedState.mode = "select";
        }
        this.setState(updatedState);
    };

    duplicateVisitor = (index) => () => {
        const dq = this.props.dataRequest;
        if (index < dq.customers.length) {
            const visitorCopy = JSON.parse(JSON.stringify(dq.customers[index]));
            visitorCopy.type = "Copy of " + visitorCopy.type;
            dq.customers.push(visitorCopy);
            this.props.requestUpdated && this.props.requestUpdated(dq);
            this.setState({"mode": "visitor", "index": dq.customers.length - 1});
        }
    };
    handleCloseSnackbar = () => {
        this.setState({snackbarOpen: false});
    };

    render() {
        const classes = this.props.classes;
        return (<div>
            <Snackbar open={this.state.duplicateName} autoHideDuration={6000} message="Duplicate Name" />
            <Grid container>
                <Grid item xs={12}>
                    <TextField id="name-field" label="Name" helperText="Simulation Name" fullWidth defaultValue={this.state.name} onBlur={(evt) => this.resetName(evt.target.value)}/>
                </Grid>
                <Grid item xs={6} sm={5} md={4} lg={3}>
                    <div>
                        <List className={classes.root}>
                            <ListItem button>
                                <ListItemText inset primary="Inventory Data" onClick={this.setupCoreData}/>
                            </ListItem>
                            <ListItem button onClick={this.handleOpenVisitors}>
                                <ListItemText inset primary="Customers"/>
                                {this.state.visitorsOpen ? <ExpandLess/> : <ExpandMore/> }
                            </ListItem>
                            <Collapse in={this.state.visitorsOpen} unmountOnExit>
                                {this.props.dataRequest.customers.map((entry, index) => {
                                    return (<ListItem button className={classes.nested} onClick={this.editVisitor(index)} key={index}>
                                        <ListItemText inset primary={entry.type} />
                                        <ListItemSecondaryAction>
                                            <IconButton dense="true" onClick={this.duplicateVisitor(index)}><ContentCopy/></IconButton>
                                            <IconButton dense="true" onClick={this.removeVisitor(index)}><Delete/></IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>);
                                })}
                                <ListItem button className={classes.nested} onClick={this.createNewVisitor} key="__createNew">
                                    <ListItemText inset primary="Create New" />
                                </ListItem>
                            </Collapse>
                            <ListItem button onClick={this.handleOpenPricePoints}>
                                <ListItemText inset primary="Pricing Strategies"/>
                                {this.state.pricePointOpen ? <ExpandLess/> : <ExpandMore/> }
                            </ListItem>
                            <Collapse in={this.state.pricePointOpen} unmountOnExit>
                                <ListItem button onClick={this.handleOpenStaticPrice} className={classes.nested}>
                                    <ListItemText inset primary="Static Price" />
                                    {this.state.staticPricePointsOpen ? <ExpandLess/> : <ExpandMore/> }
                                </ListItem>
                                <Collapse in={this.state.staticPricePointsOpen} unmountOnExit>
                                    {this.props.dataRequest.staticConfig.map((entry, index)=> {
                                        return (<ListItem button className={classes.doubleNested} key={index} onClick={this.editStaticPrice(index)}>
                                            <ListItemText inset primary={entry.name} />
                                            <ListItemSecondaryAction>
                                                <IconButton dense="true" onClick={this.duplicateStatic(index)}><ContentCopy/></IconButton>
                                                <IconButton dense="true" onClick={this.removeStatic(index)}><Delete/></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>);
                                    })}
                                    <ListItem button className={classes.doubleNested} onClick={this.createNewStaticSimulation}>
                                        <ListItemText inset primary="Create New" />
                                    </ListItem>
                                </Collapse>
                                <ListItem button onClick={this.handleOpenPIDPrice} className={classes.nested}>
                                    <ListItemText inset primary="PID Price" />
                                    {this.state.pidPricePointsOpen ? <ExpandLess/> : <ExpandMore/> }
                                </ListItem>
                                <Collapse in={this.state.pidPricePointsOpen} unmountOnExit>
                                    {this.props.dataRequest.pidConfig.map((entry, index) => {
                                        return (<ListItem button className={classes.doubleNested} key={index} onClick={this.editPidPrice(index)}>
                                            <ListItemText inset primary={entry.name} />
                                            <ListItemSecondaryAction>
                                                <IconButton dense="true" onClick={this.duplicatePid(index)}><ContentCopy/></IconButton>
                                                <IconButton dense="true" onClick={this.removePid(index)}><Delete/></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>);
                                    })}
                                    <ListItem button className={classes.doubleNested} onClick={this.createNewPidSimulation}>
                                        <ListItemText inset primary="Create New" />
                                    </ListItem>
                                </Collapse>
                                <ListItem button onClick={this.handleOpenLinearPrice} className={classes.nested}>
                                    <ListItemText inset primary="Linear Price" />
                                    {this.state.linearPricePointsOpen ? <ExpandLess/> : <ExpandMore/> }
                                </ListItem>
                                <Collapse in={this.state.linearPricePointsOpen} unmountOnExit>
                                    {this.props.dataRequest.linearDemandConfig.map((entry, index) => {
                                        return (<ListItem button className={classes.doubleNested} key={index} onClick={this.editLinearPrice(index)}>
                                            <ListItemText inset primary={entry.name} />
                                            <ListItemSecondaryAction>
                                                <IconButton dense="true" onClick={this.duplicateLinear(index)}><ContentCopy/></IconButton>
                                                <IconButton dense="true" onClick={this.removeLinear(index)}><Delete/></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>);
                                    })}
                                    <ListItem button className={classes.doubleNested} onClick={this.createNewLinearSimulation}>
                                        <ListItemText inset primary="Create New" />
                                    </ListItem>
                                </Collapse>
                                <ListItem button onClick={this.handleOpenEMSRPrice} className={classes.nested}>
                                    <ListItemText inset primary="EMSR Price" />
                                    {this.state.emsrPricePointsOpen ? <ExpandLess/> : <ExpandMore/> }
                                </ListItem>
                                <Collapse in={this.state.emsrPricePointsOpen} unmountOnExit>
                                    {this.props.dataRequest.emsrConfig.map((entry, index) => {
                                        return (<ListItem button className={classes.doubleNested} key={index} onClick={this.editEmsrPrice(index)}>
                                            <ListItemText inset primary={entry.name} />
                                            <ListItemSecondaryAction>
                                                <IconButton dense="true" onClick={this.duplicateEmsr(index)}><ContentCopy/></IconButton>
                                                <IconButton dense="true" onClick={this.removeEmsr(index)}><Delete/></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>);
                                    })}
                                    <ListItem button className={classes.doubleNested} onClick={this.createNewEmsrSimulation}>
                                        <ListItemText inset primary="Create New" />
                                    </ListItem>
                                </Collapse>
                            </Collapse>
                            <Divider />
                        </List>
                    </div>
                </Grid>
                <Grid item xs={6} sm={7} md={8} lg={9}>
                    {this.state.mode === "select" && <div>Select option below</div>}
                    {this.state.mode === "core" && <CoreDataEdit dataRequest={this.props.dataRequest} requestUpdated={this.props.requestUpdated}/>}
                    {this.state.mode === "visitor" && <VisitorEdit dataRequest={this.props.dataRequest} visitorIndex={this.state.index} requestUpdated={this.props.requestUpdated}/>}
                    {this.state.mode === "pid" && <div><PidPriceEdit dataRequest={this.props.dataRequest} priceIndex={this.state.index} requestUpdated={this.props.requestUpdated}/></div>}
                    {this.state.mode === "linear" && <div><LinearPriceEdit dataRequest={this.props.dataRequest} priceIndex={this.state.index} requestUpdated={this.props.requestUpdated}/></div>}
                    {this.state.mode === "static" && <div><StaticPriceEdit dataRequest={this.props.dataRequest} priceIndex={this.state.index} requestUpdated={this.props.requestUpdated} /></div>}
                    {this.state.mode === "emsr" && <div><EMSREdit dataRequest={this.props.dataRequest} priceIndex={this.state.index} requestUpdated={this.props.requestUpdated} /></div>}
                </Grid>
            </Grid>
            <Snackbar open={this.state.snackbarOpen} onRequestClose={this.handleCloseSnackbar} message={"Loaded " + this.state.filename} autoHideDuration={5000}/>
        </div>);
    }
}
RequestPage.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(style)(RequestPage);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BaseChart from './BaseChart.js';


const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class AverageRevenuePerPriceChart extends React.Component {

    render() {
        if (this.props.response) {
            const req = this.props.dataRequest;
            const resp = this.props.response;
            const configNames = req.staticConfig.concat(req.pidConfig).concat(req.linearDemandConfig).concat(req.emsrConfig).map((config) => config.name);
            const lastRow = resp.dataRow[resp.dataRow.length - 1];
            const lastVisitor = lastRow.visitors[lastRow.visitors.length - 1];
            const data = configNames.map((name) => {
                const runEntry = lastVisitor.runEntries[name];
                if (runEntry.soldCount > 0) {
                    return {
                        y: [runEntry.accumulativeRevenue],
                        x: [runEntry.accumulativeRevenue / runEntry.soldCount],
                        name: name,
                        type: "scatter",
                        mode: "markers",
                        marker: { size: 20 }
                    }
                } else {
                    return {
                        y: [runEntry.accumulativeRevenue],
                        x: [0],
                        name: name,
                        type: "scatter",
                        size: 50,
                        mode: "markers",
                        marker: { size: 20 }
                    }
                }
            });
            return <BaseChart data={data} title="Total Revenue per Pricing Strategy" xTitle="Average Price" yTitle="Total Revenue"/>
        } else {
            return <div>Awaiting data</div>
        }
    }
}
AverageRevenuePerPriceChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(AverageRevenuePerPriceChart)
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FileDownload from '@material-ui/icons/CloudDownload';
import {FormControlLabel} from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class DownloadLink extends React.Component {

    generateCSV = () => {
        let result = "Row,Day,Customer Type,Customer Group Size,Customer Budget,Expected Sale Count";
        const names = this.props.dataRequest.staticConfig.concat(this.props.dataRequest.pidConfig).concat(this.props.dataRequest.linearDemandConfig).map((config) => config.name);
        names.forEach(function(cfg) {
            result += ("," + cfg + " Sale Price," + cfg + " Sold Count," + cfg + " Accumulative Revenue");
        });
        result += "\n";
        let rowIndex = 0;
        let dayIndex = 0;
        this.props.response.dataRow.forEach(function(row) {
            dayIndex++;
            row.visitors.forEach(function(visitor) {
                result += (++rowIndex) + "," + dayIndex + "," + visitor.customerEntry.customerType + "," + visitor.customerEntry.groupSize
                    + "," + visitor.customerEntry.maxCustomerSale + "," + row.expectedSaleCount;
                names.forEach(function(name) {
                    result += "," + visitor.runEntries[name].salePrice + "," + visitor.runEntries[name].soldCount + "," + visitor.runEntries[name].accumulativeRevenue;
                });
                result += "\n";
            });
        });
        return result;
    };

    download = () => {
        const blob = new Blob([this.generateCSV()], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        if (typeof link.download === 'string') {
            document.body.appendChild(link); //Firefox requires the link to be in the body
            link.download = this.props.response.seed + "_response.csv";
            link.href = url;
            link.click();
            document.body.removeChild(link); //remove the link when done
        }
    };

    render() {
        if (this.props.response) {
            return (<FormControlLabel control={<Button onClick={this.download} color="primary"><FileDownload/></Button>} label="Download Simulation Data" />);
        } else {
            return (<div>Awaiting data</div>);
        }
    }
}
DownloadLink.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(DownloadLink);
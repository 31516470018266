import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BaseChart from './BaseChart.js';

const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class SaleByVisitorChart extends React.Component {
    render() {
        if (this.props.response) {
            const req = this.props.dataRequest;
            const resp = this.props.response;
            const visitorTypes = req.customers.map((entry) => entry.type);
            const configNames = req.staticConfig.concat(req.pidConfig).concat(req.linearDemandConfig).concat(req.emsrConfig).map((config) => config.name);
            const counts = {};
            const soldSeats = {};
            configNames.forEach((name) => {
                const thisCounts = {};
                visitorTypes.forEach((vtype) => {thisCounts[vtype] = 0});
                counts[name] = thisCounts;
                soldSeats[name] = 0;
            });
            resp.dataRow && resp.dataRow.forEach((row) => {
                row.visitors.forEach((visitor) => {
                    configNames.forEach((simulationType) => {
                        const runEntry = visitor.runEntries[simulationType];
                        if (soldSeats[simulationType] !== runEntry.soldCount) {
                            counts[simulationType][visitor.customerEntry.customerType] += visitor.customerEntry.groupSize;
                            soldSeats[simulationType] = runEntry.soldCount;
                        }
                    });
                });
            });
            console.log("SaleByVisitorChart", counts);
            const data = visitorTypes.map((name) => {
                return {
                    name: name,
                    y: configNames,
                    x: configNames.map((config) => counts[config][name]),
                    orientation: "h",
                    type: 'bar'
                }});
            console.log("data", data);
            return <BaseChart data={data} title="Sales per customer type by pricing strategy" layout={{barmode: "stack", margin: {
                l: 200,
                r: 50,
                b: 100,
                t: 100,
                pad: 4
            },
                hovermode: 'closest'}}/>
        } else {
            return <div>Awaiting data</div>
        }
    }
}
SaleByVisitorChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(SaleByVisitorChart)
import React, { Component } from 'react';
import {
    IconButton,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@material-ui/core";
import Delete from '@material-ui/icons/Delete';
import FileUpload from '@material-ui/icons/CloudUpload';
import FileDownload from '@material-ui/icons/CloudDownload';
import Refresh from '@material-ui/icons/Refresh';
import Create from '@material-ui/icons/Create';
import Launch from '@material-ui/icons/Launch';

class RequestCatalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            deleterow: null
        };
        if (props.buttonsCallback) {
            props.buttonsCallback([
                <Tooltip title="Upload" key="upload"><IconButton raised="true" color="default" onClick={this.upload}><FileUpload/></IconButton></Tooltip>,
                <Tooltip title="Create New" key="createNew"><IconButton raised="true" color="default" onClick={this.createNew}><Create/></IconButton></Tooltip>,
                <Tooltip title="Reload" key="reload"><IconButton raised="true" color="default" onClick={this.refresh}><Refresh/></IconButton></Tooltip>
                ]);
        }
        this.refresh();
    }

    onChange = (rows) => {
        this.setState({rows: rows});
    };


    upload = () => {
        document.getElementById("fileUploader").click();
    };

    download = (x) => {
        this.props.collection.find({"_id": x}).first().then(data => {
            const blob = new Blob([JSON.stringify(data.dataRequest)], {type: "application/json"});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            if (typeof link.download === 'string') {
                document.body.appendChild(link); //Firefox requires the link to be in the body
                link.download = data.name + ".json";
                link.href = url;
                link.click();
                document.body.removeChild(link); //remove the link when done
            }
        });
    };

    handleFileUpload = () => {
        const rqpage = this;
        const filelist = document.getElementById("fileUploader").files;
        const documents = [];
        for (let i = 0 ; i < filelist.length ; i++) {
            const reader = new FileReader();
            const name = filelist[i].name;
            const subname = name.toLowerCase().endsWith(".json") ? name.substring(0, name.length - 5) : name;
            reader.onload = function(data) {
                const doc = {
                    dataRequest: rqpage.sanitize(JSON.parse(data.target.result)),
                    name: subname,
                    lastUpdated: new Date(filelist[i].lastModified),
                    owner_id: rqpage.props.user.id
                };
                documents.push(doc);
                if (documents.length === filelist.length) {
                    rqpage.props.collection.insertMany(documents).then(() => {
                        rqpage.refresh();
                    });
                }
            };
            reader.readAsText(filelist[i]);
        }
    };

    refresh = () => {
        this.props.collection.find({}, {projection:
                {"_id": 1, "name": 1, "lastUpdated": 1}}).toArray().then(this.onChange);
    };

    sanitize = (dataRequest) => {
        if (!dataRequest.emsrConfig) {
            dataRequest.emsrConfig = [];
        }
        if (!dataRequest.linearDemandConfig) {
            dataRequest.linearDemandConfig = [];
        }
        if (!dataRequest.pidConfig) {
            dataRequest.pidConfig = [];
        }
        if (!dataRequest.staticConfig) {
            dataRequest.staticConfig = [];
        }
        if (!dataRequest.customers) {
            dataRequest.customers = [];
        }
        return dataRequest;
    };

    createNew = () => {
        const dataRequest = {
            "pidConfig": [],
                "staticConfig": [],
                "linearDemandConfig": [],
                "emsrConfig": [],
                "customers": [],
                "salesForecast": {
                "name": "FixedCumulative",
                    "params": [0]
            },
            "simulationDayCount": 180,
                "maxEntities": 0,
                "maxRateChange": 100,
        };
        this.props.loadRequest({
            name: "",
            dataRequest: dataRequest,
            id: null,
        });
    };

    load = (row) => {
        this.props.collection.find({"_id": row._id}).first().then(this.props.loadRequest);
    };

    delete = () => {
        const row = this.state.deleterow;
        this.state.rows.splice(this.state.rows.indexOf(row));
        this.props.collection.deleteOne({"_id": row._id}).then(() => {
            console.log("Removed ", row);
            this.setState({deleterow: null});
        }).catch((error) => console.error("Error removing " + row, error));
    };

    render() {
        return [<Table key="table">
            <TableHead>
                <TableRow>
                    <TableCell align="left">
                        <input type="file" id="fileUploader" accept="application/json" style={{display:"none"}} onChange={this.handleFileUpload} multiple={true}/>
                        Name
                    </TableCell>
                    <TableCell align="right">
                        Last Modified
                    </TableCell>
                    <TableCell align="center">
                        Controls
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    this.state.rows.map(row => (
                        <TableRow key={row._id.toString()}>
                            <TableCell align="left"><div style={{cursor: 'pointer'}} onClick={() => this.load(row)}>{row.name}</div></TableCell>
                            <TableCell align="right">{row.lastUpdated.toISOString()}</TableCell>
                            <TableCell align="center"><Tooltip title="Launch"><IconButton onClick={() => this.load(row)}><Launch/></IconButton></Tooltip><Tooltip title="Download"><IconButton onClick={() => this.download(row._id)}><FileDownload/></IconButton></Tooltip><Tooltip title="Delete"><IconButton color="secondary" onClick={() => this.setState({deleterow: row})}><Delete/></IconButton></Tooltip></TableCell>
                        </TableRow>
                ))}
            </TableBody>
        </Table>,
        <Dialog open={this.state.deleterow !== null} key="deletedialog">
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>Really delete {this.state.deleterow && this.state.deleterow.name}?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <IconButton color="secondary" onClick={this.delete}>Yes</IconButton>
                <IconButton color="primary" onClick={() => this.setState({deleterow: null})}>No</IconButton>
            </DialogActions>
        </Dialog>]
    }
}
export default RequestCatalog;
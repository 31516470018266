import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import {MenuItem} from '@material-ui/core';
import BaseChart from './responsecomponents/BaseChart.js';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    dialog: {
        width: '80%',
        maxHeight: 435,
    },
});

class FillDialog extends React.Component {
    componentWillMount() {
        const values = this.getValues();
        this.setState({
            controls: {
                start: 0,
                end: values.length,
                value: 0,
                mode: 'fixed',
                slope: 0,
                constant: 0,
                startValue: 0,
                endValue: 0,
                power: 1,
                base: 10
            },
            values: values
        });
    }

    getValues = () => {
        if (this.props.values) {
            return this.props.values.slice();
        }
        else if (this.props.max) {
            return new Array(this.props.max).fill(0);
        }
        else {
            return [];
        }
    };

    setValue = (name, type) => (event) => {
        const controls = this.state.controls;
        if (type === "integer") {
            controls[name] = parseInt(event.target.value, 10);
        } else if (type === "float") {
            controls[name] = parseFloat(event.target.value);

        } else {
            controls[name] = event.target.value;
        }
        if (controls[name].isNaN) {
            controls[name] = 0;
        }
        const values = this.updateValues(controls);
        this.setState({controls: controls, values: values});
    };

    updateValues = (controls) => {
        let values = this.getValues();
        const range = controls.endValue - controls.startValue;
        switch (controls.mode) {
            case 'fixed':
                values.fill(controls.value, controls.start, controls.end);
                break;
            case 'line':
                for (let x = controls.start ; x < controls.end ; x++) {
                    values[x] = (controls.slope * (x - controls.start)) + (controls.constant);
                }
                break;
            case 'log':
                for (let x = controls.start ; x < controls.end ; x++) {
                    values[x] = controls.startValue + ((Math.log(x - controls.start) / Math.log(controls.end - controls.start))) * range;
                }
                break;
            case 'exponential':
                const max = Math.pow((controls.end - controls.start), controls.power);
                for (let x = controls.start ; x < controls.end ; x++) {
                    values[x] = controls.startValue + (Math.pow(x - controls.start, controls.power) / max) * range;
                }
                break;
            case 'trigonometric':
                for (let x = controls.start ; x < controls.end ; x++) {
                    const pos = (x - controls.start) / (controls.end - controls.start);
                    values[x] = controls.startValue + (Math.pow(Math.sin(pos * Math.PI/2), controls.power) * range);
                }
                break;
            default:
                console.log("Mode " + controls.mode + " not yet supported");
        }
        if (this.props.round) {
            values = values.map((x) => Math.round(x));
        }
        return values;
    };

    handleOk = () => {
        this.props.onRequestClose({values: this.state.values, start: this.state.controls.start, end: this.state.controls.end});
    };
    handleCancel = () => {
        this.setState({values: this.getValues()});
        this.props.onRequestClose({});
    };

    handleClose = () => {
        this.handleCancel();
    };

    render() {
        const {max} = this.props;
        const controls = this.state.controls;
        const mode = controls.mode;
        const data = [
            {
                x: this.state.values.map((_, index) => index),
                y: this.state.values,
                name: "New Value"
            },
            {
                x: this.props.values.map((_, index) => index),
                y: this.props.values,
                name: "Original Value"
            }
        ];
        return <Dialog open={this.props.open} onRequestClose={this.handleClose}>
            <DialogTitle>Fill area</DialogTitle>
            <DialogContent>
                <div>
                        <Select value={mode} onChange={this.setValue('mode', "string")}>
                            <MenuItem value="fixed">Fixed</MenuItem>
                            <MenuItem value="line">Line</MenuItem>
                            <MenuItem value="log">Logarithmic</MenuItem>
                            <MenuItem value="exponential">Exponential</MenuItem>
                            <MenuItem value="trigonometric">Trigonometric</MenuItem>
                        </Select>
                <Grid container>
                    <Grid item xs={2}>
                        <TextField value={controls.start} type="number" onChange={this.setValue("start", "integer")} label="Start" inputProps={{"min": 0, "max": max}} error={controls.start > max || controls.end <= controls.start}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={controls.end} type="number" onChange={this.setValue("end", "integer")} label="End" inputProps={{"min": 0, "max": max}} error={controls.end > max || controls.end <= controls.start}/>
                    </Grid>
                    <Grid item xs={2} hidden={"fixed" !== mode}>
                        <TextField value={controls.value} type="number" onChange={this.setValue("value", "float")} label="Value" inputProps={{"min": 0}} error={controls.value < 0}/>
                    </Grid>
                    <Grid item xs={2} hidden={"line" !== mode}>
                        <TextField value={controls.constant} type="number" onChange={this.setValue("constant", "float")} label="Constant" />
                    </Grid>
                    <Grid item xs={2} hidden={"line" !== mode}>
                        <TextField value={controls.slope} type="number" onChange={this.setValue("slope", "float")} label="Slope" />
                    </Grid>
                    <Grid item xs={2} hidden={(mode === "fixed" || mode === "line")}>
                        <TextField value={controls.startValue} type="number" onChange={this.setValue("startValue", "float")} label="Start Value" />
                    </Grid>
                    <Grid item xs={2} hidden={(mode === "fixed" || mode === "line")}>
                        <TextField value={controls.endValue} type="number" onChange={this.setValue("endValue", "float")} label="End Value" />
                    </Grid>
                    <Grid item xs={2} hidden={(mode !== "exponential" && mode !== "trigonometric")}>
                        <TextField value={controls.power} type="number" onChange={this.setValue("power", "float")} label="Power" />
                    </Grid>
                    <Grid item xs={12} hidden={this.props.max === 0}>
                        <BaseChart data={data} title="Values" chartType="LineChart" xAxis="Index" yAxis="Value"  layout={{
                            width: 600,
                            height: 400
                        }}/>
                    </Grid>
                </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCancel} color="primary">Cancel</Button>
                <Button onClick={this.handleOk} color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    }
}
export default withStyles(styles)(FillDialog)
import React, { Component } from 'react';
import './App.css';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Simulator from './components/Simulator.js';
import Beforeunload from 'react-beforeunload';

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
//injectTapEventPlugin();
const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
});
//Use this URL if you are developing the UI without a local simulator instance
const serviceURL = "https://i5yz0rnta0.execute-api.eu-west-1.amazonaws.com/dev/DynamicPriceSimulator";
let appId = "dynamicpricingstore-jrlpb";
//Change this URL to your local install if you are also working on the core simulator code
//const serviceURL = "http://10.160.2.38:8080/simulator";
//const serviceURL = "http://localhost:8080/simulator";
// const serviceURL = "https://ws.pi.ourhouse.synology.me/simulator";
class App extends Component {

  render() {
    return (
        <Beforeunload onBeforeunload={() => "Really quit?"}>
            <MuiThemeProvider theme={theme}>
                <div>
                    <Simulator serviceURL={serviceURL} appId={appId} location={this.props.location}/>
                </div>
            </MuiThemeProvider>
        </Beforeunload>
    );
  }
}

export default App;

import React, { Component } from 'react';
import {AppBar, IconButton, Tab, Tabs, Tooltip} from "@material-ui/core";
import TabContainer from './TabContainer.js';
import RequestPage from "./RequestPage";
import ResponsePage from "./ResponsePage.js";
import Button from '@material-ui/core/Button';
import { Stitch, RemoteMongoClient } from 'mongodb-stitch-browser-sdk'
import Login from "./Login";
import RequestCatalog from "./RequestCatalog";
import AccountCircle from '@material-ui/icons/AccountCircle';

class Simulator extends Component {
    constructor(props) {
        super(props);
        const client = Stitch.initializeDefaultAppClient(props.appId);
        const mongodb = client.getServiceClient(RemoteMongoClient.factory,"mongodb-atlas");
        const db = mongodb.db("dynamicpricing");
        this.state = {
            tab: 0,
            name: null,
            dataRequest: null,
            signedIn: client.auth.isLoggedIn,
            userProfile: client.auth.user,
            client: client,
            db: db,
            collection: db.collection("requests")
        };
    }
    handleTabSwitch = (event, value) => {
        this.setState({
            tab: value,
            buttons: null
        });
    };

    doSend = (dataRequest) => {
        this.setState({
            dataRequest: dataRequest,
            tab: 2
        });

    };

    requestUpdated = (dataRequest) => {
        if (dataRequest.linearDemandConfig === undefined) {
            dataRequest.linearDemandConfig = [];
        }
        if (dataRequest.pidConfig === undefined) {
            dataRequest.pidConfig = [];
        }
        if (dataRequest.staticConfig === undefined) {
            dataRequest.staticConfig = [];
        }
        if (dataRequest.emsrConfig === undefined) {
            dataRequest.emsrConfig = [];
        }
        dataRequest.pidConfig.forEach(function(x) {
            if (x.stickWithStartPrice === undefined) {
                x.stickWithStartPrice = true;
            }
            if (x.resetForecast === undefined) {
                x.resetForecast = false;
            }
        });
        this.setState({
            dataRequest: dataRequest
        });
    };

    loadRequest = (row) => {
        console.debug("Load request", row);
        this.setState({
            name: row.name,
            dataRequest: row.dataRequest,
            id: row._id,
            tab: 1,
            buttons: null
        });
    };

    signIn(userProfile) {
        console.log("signIn", userProfile);
        this.setState({signedIn: true, userProfile: userProfile});
        console.log(this.state);
    }

    logout = () => {
        console.log(this.state);
        this.state.client.auth.logout().then(() => this.setState({
            signedIn: false,
            userProfile: null
        }));
    };

    renderLogout = () => {
        if (this.state.userProfile.profile.pictureUrl != null) {
            return <img src={this.state.userProfile.profile.pictureUrl} alt={this.state.userProfile.profile.name}/>
        } else {
            return <AccountCircle/>
        }
    };

    setButtons = (buttons) => {
        this.setState({
            buttons: buttons
        });
    };

    render() {
        const tab = this.state.tab;
        console.log(this.state.buttons);
        if (this.state.signedIn) {
            return (<div>
                <AppBar position="static">
                    <div>
                        <span className="tabControls">
                        <Tabs value={tab} onChange={this.handleTabSwitch}>
                            <Tab label="Request Catalog" />
                            <Tab label="Request Data" disabled={this.state.name === null}/>
                            <Tab label="Response Data" disabled={this.state.name === null}/>
                        </Tabs>
                        </span>
                        <span className="buttons">
                            {this.state.buttons && this.state.buttons}
                            <Tooltip title="Sign Out"><IconButton onClick={this.logout}>{this.renderLogout()}</IconButton></Tooltip>
                        </span>
                    </div>
                </AppBar>
                {tab === 0 && <TabContainer><RequestCatalog collection={this.state.collection} user={this.state.userProfile} buttonsCallback={this.setButtons} loadRequest={this.loadRequest} /></TabContainer>}
                {tab === 1 && <TabContainer><RequestPage collection={this.state.collection} buttonsCallback={this.setButtons} user={this.state.userProfile} id={this.state.id} name={this.state.name} dataRequest={this.state.dataRequest} requestUpdated={this.requestUpdated} onComplete={this.doSend}/></TabContainer>}
                {tab === 2 && <TabContainer><ResponsePage dataRequest={this.state.dataRequest} buttonsCallback={this.setButtons} requestUpdated={this.requestUpdated} serviceURL={this.props.serviceURL} /></TabContainer>}
            </div>);
        }
        else {
            return <Login client={this.state.client} onLogin={(user) => this.signIn(user)} location={this.props.location}/>
        }
    }
}
export default Simulator;
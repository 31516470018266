
import React from "react";
import { UserPasswordCredential, UserPasswordAuthProviderClient} from 'mongodb-stitch-browser-sdk'
import LoginDialog from "./LoginDialog";
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            password: '',
            error: false
        };
        console.log(this.props.location, this.props.location.pathname);
        if (this.props.location !== null && this.props.location.pathname === "/confirm") {
                this.confirm();
        }
    };

    confirm = () => {
        console.log(this.props.location, this.props.location.pathname);
        const params = new URLSearchParams(this.props.location.search);
        const token = params.get("token");
        const tokenId = params.get("tokenId");
        console.log(params, token, tokenId);
        if (token && tokenId) {
            const emailPassClient = this.state.client.auth
                .getProviderClient(UserPasswordAuthProviderClient.factory);
            const confirm = emailPassClient.confirmUser(token, tokenId);
            console.log(confirm);
            this.setState({confirmed: true});
        }
    };

    handleClick(event) {
        event.preventDefault();
        console.log(this.state);
        if (this.state.user !== "") {
            this.props.client.auth.loginWithCredential(new UserPasswordCredential(this.state.user, this.state.password)).then(user => {
                console.log(user);
                console.log(this.props);
                if (this.props.onLogin) {
                    this.props.onLogin(user);
                }
            }, error => {
                console.log(error);
                this.setState({error: true})
            });
        }
    };
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };
    getMode = () => {
        if (this.props.location !== null && this.props.location.pathname === "/reset") {
            return "reset";
        }
        else {
            return "login";
        }
    };

    render() {
        const mode = this.getMode();
        if (mode === "reset") {
            const params = new URLSearchParams(this.props.location.search);
            const token = params.get("token");
            const tokenId = params.get("tokenId");
            return <LoginDialog client={this.props.client} onLogin={this.props.onLogin} initMode={mode} token={token} tokenId={tokenId}/>
        }
        else {
            return <LoginDialog client={this.props.client} onLogin={this.props.onLogin} initMode={mode} />
        }
    }
}
export default Login;

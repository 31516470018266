import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FillDialog from './FillDialog.js';

const styles = theme => ({
    container: {
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    bigTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});
class VisitorEdit extends React.Component {

    constructor(props) {
        super(props);
        //Clone the visitor
        const visitor = this.setupVisitor(props.dataRequest, props.dataRequest.customers[props.visitorIndex]);
        this.state = {
            visitor: visitor,
            fillDialogOpen: false
        };

    }

    componentWillReceiveProps = function(newprops) {
        this.setState({
            visitor: this.setupVisitor(newprops.dataRequest, newprops.dataRequest.customers[newprops.visitorIndex]),
            fillDialogOpen: false
        });
    };

    openDialog = () => {
        this.setState({"fillDialogOpen": true});
    };

    closeDialog = (data) => {
        const visitor = this.state.visitor;
        if (data.start !== undefined && data.end !== undefined) {
            for (let i = data.start ; i < data.end ; i++) {
                visitor.advancePurchase.params[i] = data.values[i];
            }
            this.updateVisitor(visitor);
        }
        this.setState({"fillDialogOpen": false});
    };

    updateVisitor(v) {
        const dq = this.props.dataRequest;
        dq.customers[this.props.visitorIndex] = v;
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({visitor: v})
    }
    handleSetVisitorRoot = (name, type) => event => {
        const visitor = this.state.visitor;
        if (type === "integer") {
            visitor[name] = parseInt(event.target.value, 10);
        } else if (type === "float") {
            visitor[name] = parseFloat(event.target.value);
        } else {
            visitor[name] = event.target.value;
        }
        this.updateVisitor(visitor);
    };

    handleSetVisitorSale = name => event => {
        const visitor = this.state.visitor;
        visitor.saleProbability[name] = parseFloat(event.target.value);
        this.updateVisitor(visitor);
    };

    handleSetVisitorPurchase = index => event => {
        const visitor = this.state.visitor;
        visitor.advancePurchase.params[index] = parseInt(event.target.value, 10);
        this.updateVisitor(visitor);
    };

    setupVisitor = function(dataRequest, visitor) {
        if (!visitor.advancePurchase) {
            visitor.advancePurchase = {"params": [], "name": "Fixed"};
        }
        if (!visitor.saleProbability) {
            visitor.saleProbability = {"mean": 0, "standardDeviation": 0};
        }
        if (!visitor.groupSize) {
            visitor.groupSize = 1;
        }
        // const daySales = this.state.visitor.;
        const advPurchase = visitor.advancePurchase;
        while (advPurchase.params.length < dataRequest.simulationDayCount) {
            advPurchase.params.push(0);
        }
        while (advPurchase.params.length > dataRequest.simulationDayCount) {
            advPurchase.params.pop();
        }
        return visitor;
    };

    render() {
        const classes = this.props.classes;
        const visitor = this.state.visitor;
        const advPurchase = visitor.advancePurchase;
        return (
            <div className={classes.container}>
                <Paper>
                    <Grid container>
                        <Grid item xs={12}>
                            <div>
                                <h3>Edit Customer</h3>
                            </div>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <TextField value={"" + visitor.type} className={classes.bigTextField} onChange={this.handleSetVisitorRoot("type", "string")} label="Visitor Type"/>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <TextField inputProps={{min: 0}} value={"" + visitor.saleProbability.mean} className={classes.bigTextField} type="number" onChange={this.handleSetVisitorSale("mean")} label="Average Budget"/>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <TextField inputProps={{min: 0}} value={"" + visitor.saleProbability.standardDeviation} className={classes.bigTextField} type="number" onChange={this.handleSetVisitorSale("standardDeviation")} label="Budget Standard Deviation"/>
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <TextField type="number" inputProps={{min: 1}} value={"" + visitor.groupSize} className={classes.bigTextField} onChange={this.handleSetVisitorRoot("groupSize", "integer")} label="Group Size" />
                        </Grid>
                        <Grid item xs={4} lg={2}>
                            <Button onClick={this.openDialog} raised="true" color="primary" >Fill</Button>
                            <FillDialog open={this.state.fillDialogOpen} values={advPurchase.params} onRequestClose={this.closeDialog} round={true}/>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <h3>Customer Count By Day</h3>
                            </div>
                        </Grid>
                        {advPurchase.params.map((entry, index) => {
                            return (<Grid item xs={3} lg={1} key={index}>
                                <TextField inputProps={{"min": 0}} label={index + 1} value={"" + entry} className={classes.textField} type="number" onChange={this.handleSetVisitorPurchase(index)} />
                            </Grid>);
                        })}
                    </Grid>
                </Paper>
            </div>
        );
    }
}
VisitorEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VisitorEdit);
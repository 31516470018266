import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory'


const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class BaseChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: undefined
        };
    }

    getCustomers() {
        if (this.props.response) {
            const resp = this.props.response;
            return [{
                x: resp.dataRow.map((_, index) => index),
                y: resp.dataRow.map((row) => row.visitors.length),
                mode: "lines",
                name: "Customers",
                yaxis: "y2",
                line: {
                    dash: 'dot',
                    color: "#000000"
                }
            }];
        } else {
            return [];
        }
    }

    render() {
        if (this.props.data) {
            const data = this.props.data.concat(this.getCustomers());
            const layout = this.props.layout ? this.props.layout : {};
            if (!layout.title) {
                layout.title = this.props.title;
            }
            if (!layout.xaxis) {
                layout.xaxis = {
                    title: this.props.xTitle
                };
            }
            if (!layout.legend) {
                layout.legend = {
                    orientation: 'h'//,
                    // yanchor: 'bottom',
                    // xanchor: 'left',
                    // y: -1,
                    // x: 1
                }
            }
            if (!layout.autosize) {
                layout.autosize = true;
            }
            if (!layout.width) {
                layout.width = window.innerWidth * 0.8;
            }
            if (!layout.height) {
                layout.height = 750;
            }
            /*
            if (!layout.autosize) {
                layout.autosize = false;
            }
            if (!layout.width) {
                layout.width = 1024;
            }
            if (!layout.height) {
                layout.height = 500;
            }
            if (!layout.margin) {
                layout.margin = {
                    l: 50,
                    r: 50,
                    b: 100,
                    t: 100,
                    pad: 4
                };
            }
            */
            const secondY = data.find((entry) => entry.yaxis === "y2");
            if (secondY !== undefined) {
                let min = 0;
                let max = 0;
                for (let i = 0 ; i < data.length ; i++) {
                    if (data[i].y && "y2" === data[i].yaxis) {
                        for (let j = 0 ; j < data[i].y.length ; j++) {
                            if (data[i].y[j] > max) {
                                max = data[i].y[j];
                            }
                            if (data[i].y[j] < min) {
                                min = data[i].y[j];
                            }
                        }
                    }
                }
                const y2 = this.props.y2Title ? this.props.y2Title : "Customer Count";
                layout.yaxis2 = {
                    title: y2,
                    overlaying: 'y',
                    side: "right",
                    range: [min - min * 0.1, max + max * 0.1]
                };
            }
            if (this.props.yTitle && !layout.yaxis) {
                let min = 0;
                let max = 0;
                for (let i = 0 ; i < data.length ; i++) {
                    if (data[i].y && "y2" !== data[i].yaxis) {
                        for (let j = 0 ; j < data[i].y.length ; j++) {
                            if (data[i].y[j] > max) {
                                max = data[i].y[j];
                            }
                            if (data[i].y[j] < min) {
                                min = data[i].y[j];
                            }
                        }
                    }
                }
                layout.yaxis = {
                    title: this.props.yTitle,
                    range: [min - min * 0.1, max + max * 0.1]
                };
            }
            if (!layout.hoverlabel) {
                layout.hoverlabel = {};
            }
            if (!layout.hoverlabel.namelength) {
                layout.hoverlabel.namelength = -1;
            }
            const Plot = createPlotlyComponent(Plotly);
            return (<div><Plot data={data} layout={layout}/></div>);
        } else {
            return (<div>Awaiting data</div>);
        }
    }
}
BaseChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(BaseChart);

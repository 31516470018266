import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FillDialog from './FillDialog.js';

const styles = theme => ({
    container: {
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    bigTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});
class StaticPriceEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            price: props.dataRequest.staticConfig[props.priceIndex],
            fillDialogOpen: false
        }
    }

    componentWillMount(newProps) {
        if (newProps) {
            this.setState({
                price: newProps.dataRequest.staticConfig[newProps.priceIndex],
                fillDialogOpen: false
            });
        }
    }

    componentWillReceiveProps = function(newprops) {
         this.setState({
             price: newprops.dataRequest.staticConfig[newprops.priceIndex]
         });
     };

    updatePrice = function(price) {
        const dq = this.props.dataRequest;
        dq.staticConfig[this.props.priceIndex] = price;
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({
            price: price
        });
    };

    handleSetPriceField = (fieldName, fieldType) => event => {
        const price = this.state.price;
        let value;
        if (fieldType === "string") {
            value = event.target.value;
        } else if (fieldType === "float") {
            value = parseFloat(event.target.value);
        } else if (fieldType === "integer") {
            value = parseInt(event.target.value, 10);
        }
        price[fieldName] = value;
        this.updatePrice(price);
    };

    handleUpdatePrice = index => event => {
        const price = this.state.price;
        price.salePrice[index] = parseFloat(event.target.value);
        this.updatePrice(price);
    };

    openDialog = () => {
        this.setState({fillDialogOpen: true});
    };

    closeDialog = (data) => {
        const price = this.state.price;
        if (data.start !== undefined && data.end !== undefined) {
            for (let i = data.start ; i < data.end ; i++) {
                price.salePrice[i] = data.values[i];
            }
            this.updatePrice(price);
        }
        this.setState({fillDialogOpen: false});
    };

    render() {
        const classes = this.props.classes;
        let prices = this.state.price.salePrice;
        if (prices.length === 0) {
            prices[0] = 0;
        }
        while (prices.length < this.props.dataRequest.maxEntities) {
            prices.push(prices[prices.length - 1]);
        }
        while (prices.length > this.props.dataRequest.maxEntities) {
            prices.pop()
        }
        return (
            <div className={classes.container}>
                <Paper>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField value={this.state.price.name} className={classes.bigTextField} onChange={this.handleSetPriceField("name", "string")} label="Name"/>
                        </Grid>
                        <Grid item xs={9}>
                            <div>
                                <h3>Price Per Item</h3>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <Button raised="true" color="primary" onClick={this.openDialog}>Fill</Button>
                            <FillDialog open={this.state.fillDialogOpen} values={prices} onRequestClose={this.closeDialog}/>
                        </Grid>
                        {prices.map((entry, index) => {
                            return (<Grid item xs={2} lg={1} key={index}>
                                <TextField label={index + 1} value={entry} className={classes.textField} type="number" onChange={this.handleUpdatePrice(index)}/>
                            </Grid>);
                        })}
                    </Grid>
                </Paper>
            </div>
        );
    }
}
StaticPriceEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StaticPriceEdit);
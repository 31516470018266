import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {UserPasswordAuthProviderClient, UserPasswordCredential} from 'mongodb-stitch-browser-sdk'

class LoginDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            mode: props.initMode
        };
    }

    login = (event) => {
        event.preventDefault();
        if (this.state.user !== "") {
            this.props.client.auth.loginWithCredential(new UserPasswordCredential(this.state.username, this.state.password)).then(user => {
                console.log(user);
                console.log(this.props);
                if (this.props.onLogin) {
                    this.props.onLogin(user);
                }
            }, error => {
                console.log(error);
                this.setState({error: true})
            });
        }
    };

    register = () => {
        console.debug("Register new account");
        const factory = this.props.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
        factory.registerWithEmail(this.state.username, this.state.password).then(() => {
            console.log("Successfully sent confirmation email")
        }).catch(error => {
            if (error.errorCode === 46) {
                factory.resendConfirmationEmail(this.state.username).then(() => {
                    console.log("Resent confirmation email")
                }).catch(suberror => {
                    console.log(suberror);
                });
            }
            console.error("Failed to send confirmation email", error)
        });
    };

    forgottenPassword = () => {
        console.debug("Forgot password call");
        const factory = this.props.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
        factory.sendResetPasswordEmail(this.state.username).then(() => {
            console.log("Successfully sent password reset email");
        }).catch(error => console.error("Error sending password email", error));
    };

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };
    isDisabled = (incpassword) => {
        let retVal = this.state.username !== "" && (!incpassword || this.state.password !== "");
        return !retVal;
    };

    checkRegister = () => {
        let populated = this.state.username !== "" && this.state.password !== "" & this.state.confirmpassword === this.state.password;
        return !populated;
    };

    getAutoCompletePassword = () => {
        return this.state.mode === "register" ? "new-password" : "current-password";
    };

    reset = () => {
        const factory = this.props.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory);
        factory.resetPassword(this.props.token, this.props.tokenId, this.state.password).then((x) => {
            console.log("Successfully reset password");
        }).catch(error => console.error("Error resetting password", error));
        this.setState({mode: "login"});
    };

    render() {
        return <Dialog open={true}>
            <DialogTitle>
                {this.state.mode === "register" && <span>Register New User</span>}
                {this.state.mode === "login" && <span>Login</span>}
                {this.state.mode === "reset" && <span>Reset</span>}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {this.state.mode === "login" && <span>New User? <Button onClick={() => this.setState({"mode": "register"})}>Register Here.</Button></span>}
                    {this.state.mode === "register" && <span>Existing User? <Button onClick={() => this.setState({"mode": "login"})}>Login</Button></span>}
                    {this.state.mode === "reset" && <span>Reset password</span>}
                </DialogContentText>
                <form>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Email Address"
                        type="email"
                        fullWidth placeholder="user@datalex.com" error={false}
                        onChange={this.handleChange}
                        autoComplete="username"

                    />
                    <TextField margin="dense" id="password" label="Password" type="password" fullWidth
                               onChange={this.handleChange} autoComplete={this.getAutoCompletePassword()}/>
                    {(this.state.mode === "register" || this.state.mode === "reset") && <TextField margin="dense" id="confirmpassword" label="Confirm Password" type="password" fullWidth
                               onChange={this.handleChange} autoComplete={this.getAutoCompletePassword()}/>}
                </form>
            </DialogContent>
            <DialogActions>
                {this.state.mode === "login" && <Button onClick={this.login} color="primary" disabled={this.isDisabled(true)}>Login</Button>}
                {this.state.mode === "login" && <Button onClick={this.forgottenPassword} color="secondary" disabled={this.isDisabled(false)}>Forgot Password</Button>}
                {this.state.mode === "register" && <Button onClick={this.register} color="primary" disabled={this.checkRegister()}>Register</Button>}
                {this.state.mode === "reset" && <Button onClick={this.reset} color="primary" disabled={this.checkRegister()}>Reset Password</Button>}
            </DialogActions>
        </Dialog>
    }
}
export default LoginDialog;
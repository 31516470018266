import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

const styles = theme => ({
    container: {
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    bigTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});
class EMSREdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            price: props.dataRequest.emsrConfig[props.priceIndex]
        }
    }

    componentWillReceiveProps = function(newprops) {
        const price = newprops.dataRequest.emsrConfig[newprops.priceIndex];
        if (price && price !== this.state.price) {
            console.log("Updated price");
            if (price.protectionRows) {
                price.protectionRows = price.protectionRows.sort((a, b) => b.price - a.price);
            } else {
                price.protectionRows = [];
            }
            this.setState({
                price: price
            });
        }
    };

    updatePrice = function(price) {
        const dq = this.props.dataRequest;
        dq.emsrConfig[this.props.priceIndex] = price;
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({
            price: price
        });
    };

    handleSetPriceField = (fieldName, fieldType) => event => {
        const price = this.state.price;
        let value;
        if (fieldType === "string") {
            value = event.target.value;
        } else if (fieldType === "float") {
            value = parseFloat(event.target.value);
        } else if (fieldType === "integer") {
            value = parseInt(event.target.value, 10);
        }
        price[fieldName] = value;
        this.updatePrice(price);
    };

    handleProtectionRowField = (fieldName, index) => event => {
        const price = this.state.price;
        const row = price.protectionRows[index];
        row[fieldName] = parseFloat(event.target.value);
        this.updatePrice(price);
    };

    addRow = () => {
        const price = this.state.price;
        if (!price.protectionRows) {
            price.protectionRows = [];
        }
        price.protectionRows.push({price: 0, mean: 0, standardDeviation: 0});
        console.log(price.protectionRows);
        this.updatePrice(price);
    };

    removeRow = (index) => () => {
        const price = this.state.price;
        price.protectionRows.splice(index, 1);
        this.updatePrice(price);
    };

    render() {
        const classes = this.props.classes;
        const rows = [];
        const price = this.state.price;
        const svc = this;
        if (price.protectionRows) {
            let keyId = 0;
            price.protectionRows.forEach(function(x, index) {
                rows.push(<Grid item xs={3} key={keyId++}><TextField type="number" value={x.price} onChange={svc.handleProtectionRowField("price", index)} /></Grid>,<Grid item xs={3} key={keyId++}><TextField type="number" value={x.mean} onChange={svc.handleProtectionRowField("mean", index)} /></Grid>,<Grid item xs={3} key={keyId++}><TextField type="number" value={x.standardDeviation} onChange={svc.handleProtectionRowField("standardDeviation", index)} /></Grid>,<Grid item xs={3} key={keyId++}><Button onClick={svc.removeRow(index)}><Remove /></Button></Grid>);
            });
        }
        return (
            <div className={classes.container}>
                <Paper>
                    <Grid container>
                        <Grid item xs={3}>
                            <TextField value={this.state.price.name} className={classes.bigTextField} onChange={this.handleSetPriceField("name", "string")} label="Name"/>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl>
                                <InputLabel>Mode</InputLabel>
                                <Select value={this.state.price.mode} className={classes.bigTextField} onChange={this.handleSetPriceField("mode", "string")}>
                                    <MenuItem value="EMSRa">EMSR-a</MenuItem>
                                    <MenuItem value="EMSRb">EMSR-b</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>Price List</Grid>
                        <Grid item xs={3}>Price</Grid>
                        <Grid item xs={3}>Mean</Grid>
                        <Grid item xs={3}>Standard Deviation</Grid>
                        <Grid item xs={3}>Remove</Grid>
                        {rows}
                        <Grid item xs={9}><div>&nbsp;</div></Grid>
                        <Grid item xs={3}>
                            <Button onClick={this.addRow} color="primary"><Add/></Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}
EMSREdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EMSREdit);
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FillDialog from './FillDialog.js';
const styles = theme => ({
    container: {
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 50,
    },
    bigTextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    }
});
class PidPriceEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            price: props.dataRequest.pidConfig[props.priceIndex],
            fillDialogOpen: false
        };
    }

    componentWillReceiveProps = function(newprops) {
        this.setState({
            price: newprops.dataRequest.pidConfig[newprops.priceIndex]
        });
    };

    openDialog = () => {
        console.log(this);
        this.setState({fillDialogOpen: true});
    };

    closeDialog = (data) => {
        if (data.start !== undefined) {
            const dr = this.props.dataRequest;
            for (let x = data.start ; x < data.end ; x++) {
                dr.salesForecast.params[x] = data.values[x];
            }
            this.props.requestUpdated && this.props.requestUpdated(dr);
        }
        this.setState({fillDialogOpen: false});
    };

    updatePrice = function(price) {
        const dq = this.props.dataRequest;
        dq.pidConfig[this.props.priceIndex] = price;
        this.props.requestUpdated && this.props.requestUpdated(dq);
        this.setState({
            price: price
        });
    };

    updateForecast = event => {
        const price = this.state.price;
        price.resetForecast = event.target.checked;
        this.updatePrice(price);
    };

    updateStickWithStartPrice = event => {
        const price = this.state.price;
        price.stickWithStartPrice = event.target.checked;
        this.updatePrice(price);
    };

    handleUpdateSale = (index) => event => {
        const dr = this.props.dataRequest;
        dr.salesForecast.params[index] = parseInt(event.target.value, 10);
        this.props.requestUpdated && this.props.requestUpdated(dr);

    };
    handleSetPriceField = (fieldName, fieldType) => event => {
        const price = this.state.price;
        let value;
        if (fieldType === "string") {
            value = event.target.value;
        } else if (fieldType === "float") {
            value = parseFloat(event.target.value);
        } else if (fieldType === "integer") {
            value = parseInt(event.target.value, 10);
        }
        price[fieldName] = value;
        this.updatePrice(price);
    };

    render() {
        const classes = this.props.classes;
        const dataRequest = this.props.dataRequest;
        if (!dataRequest.salesForecast) {
            dataRequest.salesForecast = {
                "name": "FixedCumulative",
                "params": []
            }
        }
        const salesForecast = dataRequest.salesForecast.params;
        while (salesForecast.length < dataRequest.simulationDayCount) {
            salesForecast.push(salesForecast[salesForecast.length - 1]);
        }
        while (salesForecast.length > dataRequest.simulationDayCount) {
            salesForecast.pop();
        }
        return (
            <div className={classes.container}>
                <Paper>
                    <Grid container>
                        <Grid item xs={3}>
                            <TextField value={this.state.price.name} className={classes.bigTextField} onChange={this.handleSetPriceField("name", "string")} label="Name"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.startPrice} className={classes.bigTextField} onChange={this.handleSetPriceField("startPrice", "float")} label="Start Price"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.kp} className={classes.bigTextField} onChange={this.handleSetPriceField("kp", "float")} label="kp"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.ki} className={classes.bigTextField} onChange={this.handleSetPriceField("ki", "float")} label="ki"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.kd} className={classes.bigTextField} onChange={this.handleSetPriceField("kd", "float")} label="kd"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.round} className={classes.bigTextField} onChange={this.handleSetPriceField("round", "integer")} label="round"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.minPrice} className={classes.bigTextField} onChange={this.handleSetPriceField("minPrice", "integer")} label="Floor"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.maxPrice} className={classes.bigTextField} onChange={this.handleSetPriceField("maxPrice", "integer")} label="Ceiling"/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={this.state.price.maxRateChange} className={classes.bigTextField} onChange={this.handleSetPriceField("maxRateChange", "integer")} label="Max Rate Change" />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel control={<Checkbox checked={this.state.price.resetForecast} onChange={this.updateForecast}/>} label="Automatically readjust forecast" />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel control={<Checkbox checked={this.state.price.stickWithStartPrice} onChange={this.updateStickWithStartPrice}/>} label="Always offset start price" />
                        </Grid>
                        <Grid item xs={9}>
                            <div><h3>Cumulative Sales Data</h3></div>
                        </Grid>
                        <Grid item xs={3}>
                            <Button raised="true" color="primary" onClick={this.openDialog}>Fill</Button>
                            <FillDialog open={this.state.fillDialogOpen} values={salesForecast} max={salesForecast.length} onRequestClose={this.closeDialog} round={true}/>
                        </Grid>
                        {salesForecast.map((entry, index) => {
                            return (<Grid item xs={1} key={index}>
                                <TextField value={entry} className={classes.textField} onChange={this.handleUpdateSale(index)} type="number" label={index + 1} error={(index > 0 && salesForecast[index - 1] > entry) || (index === 0 && entry < 0)}/>
                            </Grid>)
                        })}
                    </Grid>
                </Paper>
            </div>
        );
    }
}
PidPriceEdit.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PidPriceEdit);
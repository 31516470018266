import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BaseChart from './BaseChart.js';


const styles = {
    root: {
        width: '100%',
        marginTop: 30,
    },
};
class VisitorTypePerDayChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: undefined,
            visitorCounts: undefined
        };
    }

    findFirstIndex = (visitorName) => {
        let index = 0;
        if (this.props.response && this.props.response.dataRow) {
            const rows = this.props.response.dataRow;
            for (let i = 0 ; i < rows.length ; i++) {
                const visitors = rows[i].visitors;
                for (let j = 0 ; j < visitors.length ; j++) {
                    index++;
                    if (visitors[j].customerEntry.customerType === visitorName) {
                       return index;
                    }
                }
            }
        }
        return -1;
    };

    render() {
        if (this.props.response && this.props.response.dataRow) {
            const req = this.props.dataRequest;
            const resp = this.props.response;
            const findFirst = this.findFirstIndex;
            const visitorNames = req.customers.map(x => x.type).filter((name) => findFirst(name) > -1).sort(function(a, b) {
                return findFirst(a) - findFirst(b);
            });
            const data = visitorNames.map((name, index) => {
                const retVal = {
                    x: resp.dataRow.map((row) => row.day),
                    y: resp.dataRow.map((row) => row.visitors.filter((visitor) => visitor.customerEntry.customerType === name).length),
                    text: resp.dataRow.map((row) => row.visitors.filter((visitor) => visitor.customerEntry.customerType === name).length.toFixed(0) + " " + name),
                    name: name,
                    hoverinfo: "text",
                    type: "scatter",
                    mode: "none"
                };
                if (index === 0) {
                    retVal.fill = "tozeroy";
                } else {
                    retVal.fill = "tonexty";
                }
                return retVal;
            });
            for (let rowIdx = 1 ; rowIdx < data.length ; rowIdx++) {
                for (let colIdx = 0 ; colIdx < data[rowIdx].y.length ; colIdx++) {
                    data[rowIdx].y[colIdx] += data[rowIdx - 1].y[colIdx];
                }
            }
            return <BaseChart data={data} title="Customer Mix" xAxis="DaysOnTitle" yAxis="Customer Count"/>
        } else {
            return (<div>Awaiting data</div>);
        }
    }
}
VisitorTypePerDayChart.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(styles)(VisitorTypePerDayChart)